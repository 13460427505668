import { template as template_87bc42fad282424196743a92ec80a203 } from "@ember/template-compiler";
const SidebarSectionMessage = template_87bc42fad282424196743a92ec80a203(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
